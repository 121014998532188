@import "../../../node_modules/normalize.css/normalize";
@import "settings.scss";
@import "fonts.scss";

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*:focus {
  outline: 0;
}

html, body {
  min-height: 100%;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

html {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

body {
  display: block;
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
  line-height: 1.5;
  font-size: 100%;    
  background-color: color("background");
  color: #fff;
}

a {
  color: inherit;
  font-size: inherit;
  outline-width: 0;
  text-decoration: none;
  touch-action: manipulation;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: size("sm") 0px;
}

h1, .h1 {
  font-size: size("h1");
}

h2, .h2 {
  font-size: size("h2");
}

h3, .h3 {
  font-size: size("h3");
}

h4, .h4 {
  font-size: size("h4");
}

h5, .h5 {
  font-size: size("h5");
}

h6, .h6 {
  font-size: size("h6");
}

p {
  margin: 0;
  line-height: 1.5;
}

button {
  background: transparent;
  border-width: 0;
  border: 1px solid transparent;
  line-height: 1;
  padding: 0;
  margin: 0;
  touch-action: manipulation;
  user-select: none;

  &:not(:disabled) {
    cursor: pointer;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

input[type=text],
input[type=search],
input[type=number],
input[type=email],
input[type=tel],
input[type=password],
textarea {
  -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px color("grey-800") inset !important;
  -webkit-text-fill-color: color("grey-100");
  border-color: color("grey-700");
}

fieldset {
  padding: 0px;
  margin: size("xl") 0px;
  border: none;
  border-top: 1px solid color("grey-50");
  color: color("grey-50");
  font-size: size("text--sm");
  text-align: center;
}

legend {
  padding: 0px size("sm");
  font-weight: 400;
}

#modal-root {
  pointer-events: initial;
}

.icon {
  & + * {
    margin-left: size("sm");
  }
}

.row {
  &--xs {
    margin-top: size("xs");
    margin-bottom: size("xs");
  }

  &--sm {
    margin-top: size("sm");
    margin-bottom: size("sm");
  }

  &--md {
    margin-top: size("md");
    margin-bottom: size("md");
  }

  &--lg {
    margin-top: size("lg");
    margin-bottom: size("lg");
  }

  &--xl {
    margin-top: size("xl");
    margin-bottom: size("xl");
  }

  &:first-child {
    margin-top: 0px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.label {
  color: color("grey-50");
  max-width: 80ch;
  line-height: 1.5;
  font-weight: 400;
}

.link {
  @include link();
}

.divider {
  width: 100%;
  height: 1px;
  background-color: color("grey-800");
  margin: size("md") 0px;
}

@media only screen and (max-width: breakpoint("md") - 1px) {
  *::-webkit-scrollbar {
    display: none;
  }
}