@use 'sass:math';

@mixin row($space: 0px) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: math.div(-$space, 2);
  margin-right: math.div(-$space, 2);
}

@mixin columns($cols, $spaceX: 0px, $spaceY: 0px) {
  display: block;
  width: math.div(1, $cols) * 100% !important;
  padding-left: math.div($spaceX, 2);
  padding-right: math.div($spaceX, 2);
  padding-top: math.div($spaceY, 2);
  padding-bottom: math.div($spaceY, 2);
}

@mixin grid($numberator: 1, $denominator: 1, $spaceX: 0px, $spaceY: 0px) {
  display: block;
  width: math.div($numberator, $denominator) * 100% !important;
  padding-left: math.div($spaceX, 2);
  padding-right: math.div($spaceX, 2);
  padding-top: math.div($spaceY, 2);
  padding-bottom: math.div($spaceY, 2);
}

@mixin textFade($fontSize, $linehHeight) {
  position: absolute;
  width: 25%;
  height: $fontSize * $linehHeight;
  content: '';
  background-image: linear-gradient(270deg, #fff, hsla(0, 0%, 100%, 0));
  bottom: 0px;
  right: 0px;
  user-select: none;
}

@mixin textWrap() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin transition($duration: 250ms, $effect: ease-in-out) {
  transition-property: background-color, border-color, color, opacity;
  transition-duration: $duration;
  transition-timing-function: $effect;
}

@mixin flex($direction: row, $justification: center, $alignment: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justification;
  align-items: $alignment;
}

@mixin position($position: relative, $top: 0px, $right: 0px, $bottom: 0px, $left: 0px) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin link($color: color('grey-50')) {
  // @include transition();
  color: $color;
  border-bottom: 1px solid color('grey-50');
  text-decoration: none;
  font-weight: 400;
  line-height: 1;

  &:hover {
    color: color('grey-600');
    border-color: color('grey-600');
  }
}

@mixin scrollX() {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@mixin scrollY() {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@mixin shadow() {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
}
