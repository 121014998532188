@import 'src/assets/styles/settings';

.block {
  @include flex(column);
  padding-top: size('xl');
  text-align: center;
  color: #fff;
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: fadeInBottom;
}

.cat {
  display: block;
  width: 200px;
  height: 200px;
  background-image: url('/public/katt.png');
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: size('xl');
}

.link {
  @include link;
  margin-top: size('xl');
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
